/* import aac from "./aac.png";
import accdb from "./accdb.png";
import abw from "./abw.png";
import freearc from "./freearc.png";
import avi from "./avi.png";
import azw from "./azw.png";
import octet from "./octet.png";
import bmp from "./bmp.png";
import bz from "./bz.png";
import bz2 from "./bz2.png";
import cda from "./cda.png";
import csh from "./csh.png";
import css from "./css.png";
import csv from "./csv.png";
import docx from "./docx.png";
import drawio from "./drawio.png";
import eot from "./eot.png";
import epub from "./epub.png";
import gzip from "./gzip.png";
import gif from "./gif.png";
import html from "./html.png";
//import ico from "./ico.png";
import icalendar from "./icalendar.png";
import jar from "./java.png";
import jpeg from "./jpeg.png";
import javascript from "./javascript.png";
import json from "./json.png";
import jsonld from "./jsonld.png";
import midi from "./midi.png";
//import mjs from "./js.png";
import mp3 from "./mp3.png";
import mp4 from "./mp4.png";
import mpeg from "./mpeg.png";
import mpkg from "./mpkg.png";
import odp from "./odp.png";
import ods from "./ods.png";
import odt from "./odt.png";
//import oga from "./oga.png";
//import ogv from "./ogv.png";
//import ogx from "./ogx.png";
import opus from "./opus.png";
import otf from "./otf.png";
import png from "./png.png";
import pdf from "./pdf.png";
import php from "./php.png";
import pptx from "./pptx.png";
import psd from "./psd.png";
import rar from "./rar.png";
import rtf from "./rtf.png";
import sh from "./sh.png";
//import svg from "./svg.png";
import sass from "./sass.png";
import swf from "./swf.png";
import tar from "./rar.png";
import tiff from "./tiff.png";
import ttf from "./ttf.png";
//import ts from "./ts.png";
import typescript from "./typescript.png";
import text from "./text.png";
import vsd from "./vsd.png";
import wav from "./wav.png";
import weba from "./webm.png";
import webm from "./webm.png";
import webp from "./webp.png";
import wma from "./wma.png";
import wmv from "./wmv.png";
import woff from "./woff.png";
//import xhtml from "./xhtml.png";
import xlsx from "./xlsx.png";
import xml from "./xml.png";
import xul from "./xul.png";
import zip from "./zip.png";
//import threegp from "./3gp.png";
import sevenzip from "./7z.png";
import python from "./python.png";
import java from "./java.png";
import react from "./react.png";
import vue from "./vue.png"; */



export const sevenzip = "https://user-images.githubusercontent.com/43678736/132086517-72a51a12-e403-4675-bfd7-22c23affa730.png";
export const aac = "https://user-images.githubusercontent.com/43678736/132086518-7026d4f1-ea16-4ed0-89fd-37c1aa8ac3ed.png";
export const abw = "https://user-images.githubusercontent.com/43678736/132086519-863c63b4-917e-4471-94ff-7e15651cc14b.png";
export const accdb = "https://user-images.githubusercontent.com/43678736/132086520-9bc6aa3b-51c9-4da2-9ef7-349162b86d0b.png";
export const avi = "https://user-images.githubusercontent.com/43678736/132086521-dbd6cf0d-d4d7-4b92-bb26-17e8a51a9383.png";
export const azw = "https://user-images.githubusercontent.com/43678736/132086522-070f48e8-78a8-4294-8dbb-aab81525e164.png";
export const bmp = "https://user-images.githubusercontent.com/43678736/132086595-90ab7f90-f87e-4900-94d9-d0b26745df48.png";
export const bz = "https://user-images.githubusercontent.com/43678736/132086597-e285ad5c-613a-4679-a270-493e5be4ffd9.png";
export const bz2 = "https://user-images.githubusercontent.com/43678736/132086598-623c410a-084a-4395-a448-211b2ff61cfe.png";
export const c = "https://user-images.githubusercontent.com/43678736/132086599-7a5cd692-b4df-45f5-80d9-384cb3e0c314.png";
export const cda = "https://user-images.githubusercontent.com/43678736/132086600-8b70a007-512d-4252-9c66-eabd3ddd6573.png";
export const csh = "https://user-images.githubusercontent.com/43678736/132086601-e62e5d1a-d8a2-4475-a14f-85922cec9272.png";
export const css = "https://user-images.githubusercontent.com/43678736/132086602-4c772934-f608-4f01-8459-c4622cee8ad5.png";
export const csv = "https://user-images.githubusercontent.com/43678736/132086604-b5b019fe-572e-477e-92c2-3769a48a1304.png";
//export const default = "https://user-images.githubusercontent.com/43678736/132086605-d03db898-045c-477e-bae6-156699833eb0.png";
export const docx = "https://user-images.githubusercontent.com/43678736/132086606-715ccb66-4702-4f7d-9b09-ac93ba17b643.png";
export const docx2 = "https://user-images.githubusercontent.com/43678736/132086607-a246b386-52c9-4fe1-a7e4-204894e6722d.png";
export const drawio = "https://user-images.githubusercontent.com/43678736/132086608-bcae9d57-8e54-488c-90c4-4952ae530b5e.png";
export const dw = "https://user-images.githubusercontent.com/43678736/132086616-0c7842d6-d20e-4ede-988b-3dd063a4de8d.png";
export const eml = "https://user-images.githubusercontent.com/43678736/132086617-1e351075-ffaf-4b81-a1fe-0b7b338772a2.png";
export const eot = "https://user-images.githubusercontent.com/43678736/132086618-397d6bd2-9fda-43ed-a135-cb40388c35af.png";
export const eps = "https://user-images.githubusercontent.com/43678736/132086619-9daf0b61-dbb0-4d47-8a12-9fba13b88856.png";
export const epub = "https://user-images.githubusercontent.com/43678736/132086620-2586ba40-c583-4589-b1a4-8bb5b258b44d.png";
export const freearc = "https://user-images.githubusercontent.com/43678736/132086621-3b95fb64-2533-4ccc-abcd-bd2beba572e9.png";
export const gif = "https://user-images.githubusercontent.com/43678736/132086622-af705a0c-2b25-4ba7-8ab6-bd69ec97f7e2.png";
export const gzip = "https://user-images.githubusercontent.com/43678736/132086624-89141a46-64e4-4fa0-bf69-54a0eb4d48c9.png";
export const html = "https://user-images.githubusercontent.com/43678736/132086625-1b8f2652-1de0-4475-8c12-7da4a9973ffb.png";
export const icalendar = "https://user-images.githubusercontent.com/43678736/132086626-38699705-1e6f-4bca-984b-03167b236faa.png";
export const ind = "https://user-images.githubusercontent.com/43678736/132086627-2f24067a-00bc-424a-af36-349a9ba14b6c.png";
export const ini = "https://user-images.githubusercontent.com/43678736/132086649-20c9c9e6-8e63-4d87-9b8e-8fe8eba12ada.png";
export const java = "https://user-images.githubusercontent.com/43678736/132086650-f1166246-b361-4c30-a04e-9781c555d14a.png";
export const jar = "https://user-images.githubusercontent.com/43678736/132086650-f1166246-b361-4c30-a04e-9781c555d14a.png";
export const javascript = "https://user-images.githubusercontent.com/43678736/132086652-4562942e-aaea-466c-968f-380fffabf3f9.png";
export const jpeg = "https://user-images.githubusercontent.com/43678736/132086653-0487e7e2-1ee3-49e2-8cfe-3e20f1f7490a.png";
export const jsf = "https://user-images.githubusercontent.com/43678736/132086654-c510bd8f-8de7-4afe-8c20-cc810b004b07.png";
export const json = "https://user-images.githubusercontent.com/43678736/132086656-6e96c815-e4e2-4ffd-9d71-57e9cc2450bc.png";
export const jsonld = "https://user-images.githubusercontent.com/43678736/132086658-5d27d3c2-394f-43fb-b512-9b414a257875.png";
export const midi = "https://user-images.githubusercontent.com/43678736/132086659-98f3ef6e-b9f3-4b6d-b18f-469b5334ba27.png";
export const mov = "https://user-images.githubusercontent.com/43678736/132086660-adcecedd-56b4-4286-8b0f-69417f77e961.png";
export const mp3 = "https://user-images.githubusercontent.com/43678736/132086661-a5484553-06c7-4ffa-a8f9-96b57b1b0344.png";
export const mp4 = "https://user-images.githubusercontent.com/43678736/132086662-05ad1597-d5e5-4efa-833e-2876e966a745.png";
export const mpeg = "https://user-images.githubusercontent.com/43678736/132086663-90c58955-f7fb-4bdb-ac53-92667d16d4a3.png";
export const mpkg = "https://user-images.githubusercontent.com/43678736/132086664-9a7530e7-6d78-4ef3-a176-20cf7f57b555.png";
export const octet = "https://user-images.githubusercontent.com/43678736/132086666-ab3c505d-b2c0-4177-9a06-aed5d9c39ee4.png";
export const odp = "https://user-images.githubusercontent.com/43678736/132086667-6c7dcbcc-8d83-41a2-8e0a-85b09e2791ae.png";
export const ods = "https://user-images.githubusercontent.com/43678736/132086668-9f246e91-cf2e-49cf-9617-e1fbb71abbbb.png";
export const odt = "https://user-images.githubusercontent.com/43678736/132086669-46113762-84d1-4b32-9441-b0138ce17a5d.png";
export const opus = "https://user-images.githubusercontent.com/43678736/132086670-0f96e770-cedc-4635-a5f9-cf97894c1d7a.png";
export const otf = "https://user-images.githubusercontent.com/43678736/132086671-02ad35ef-ec3a-4a65-abd5-5bf794dfcf7b.png";
export const pdf = "https://user-images.githubusercontent.com/43678736/132086672-3a856fda-823d-4997-b802-c7c640e6ef44.png";
export const php = "https://user-images.githubusercontent.com/43678736/132086673-0c4409ab-754e-4619-8cfa-179d0ccf1bd9.png";
export const png = "https://user-images.githubusercontent.com/43678736/132086674-fdb56d02-5845-49b7-8462-6357bc963464.png";
export const pptx = "https://user-images.githubusercontent.com/43678736/132086675-c879645d-acb4-41a6-ab3c-4e6c2048badb.png";
export const pptx2 = "https://user-images.githubusercontent.com/43678736/132086676-6de1bbd7-764f-4197-9aa4-405a60ce6734.png";
export const proj = "https://user-images.githubusercontent.com/43678736/132086683-3dc0a8b8-72f8-4fa1-a08a-fcfd75b465e1.png";
export const psd = "https://user-images.githubusercontent.com/43678736/132086685-4e327c4c-a409-4b83-b36a-8d88936b314b.png";
export const pst = "https://user-images.githubusercontent.com/43678736/132086686-3888e43a-5abf-41f7-9940-4b86e436521f.png";
export const publisher = "https://user-images.githubusercontent.com/43678736/132086687-d92b56ff-f7f7-4be7-bbf4-47b8a33f4c6f.png";
export const python = "https://user-images.githubusercontent.com/43678736/132086688-8e82fae4-3a9b-49c0-bf99-77189525514c.png";
export const tar = "https://user-images.githubusercontent.com/43678736/132086689-fe1fef9f-d2db-455b-8f4b-09acd095f571.png";
export const rar = "https://user-images.githubusercontent.com/43678736/132086689-fe1fef9f-d2db-455b-8f4b-09acd095f571.png";
export const react = "https://user-images.githubusercontent.com/43678736/132086691-d472576b-ec6a-4332-acd2-dd6a00b72952.png";
export const richtextformat = "https://user-images.githubusercontent.com/43678736/132086692-df6e3518-2e6a-4553-883d-e21694980449.png";
export const rtf = "https://user-images.githubusercontent.com/43678736/132086693-9d43571e-0c86-438f-b247-e2cb42e19e06.png";
export const sass = "https://user-images.githubusercontent.com/43678736/132086694-4e661d6a-1118-441e-8bc3-c52fcb2133b6.png";
export const settings = "https://user-images.githubusercontent.com/43678736/132086696-0dd21f83-b9fc-490c-9ed5-bd88151dc9bb.png";
export const sh = "https://user-images.githubusercontent.com/43678736/132086697-1d82d724-35b6-4f06-847a-3c59a5deda6e.png";
export const swf = "https://user-images.githubusercontent.com/43678736/132086698-19384230-dbd7-4e05-bc69-ef4537b6aae3.png";
export const text = "https://user-images.githubusercontent.com/43678736/132086699-5993a482-04f4-4915-b105-9037f527cf61.png";
export const tiff = "https://user-images.githubusercontent.com/43678736/132086700-c23461c8-6819-46e1-aecd-0a1f8d3507bb.png";
export const ttf = "https://user-images.githubusercontent.com/43678736/132086701-c8044c09-8d95-4af1-9410-66761001d7da.png";
export const typescript = "https://user-images.githubusercontent.com/43678736/132086702-59294337-ed99-4302-badd-316b2c1ff62f.png";
export const url = "https://user-images.githubusercontent.com/43678736/132086703-86d97476-b76e-4949-b89a-31ecb03f3b6e.png";
export const vsd = "https://user-images.githubusercontent.com/43678736/132086704-8fd51e7c-afa2-47a3-ab2f-d0bcd0ecae9f.png";
export const vue = "https://user-images.githubusercontent.com/43678736/132086705-33294da1-5c0f-49f7-b890-e4857cec0a6d.png";
export const wav = "https://user-images.githubusercontent.com/43678736/132086706-22f805d0-39d4-494b-824e-47dc75d05eb7.png";
export const webm = "https://user-images.githubusercontent.com/43678736/132086707-e61a84de-d396-4dbf-8d1b-1d6ee19e1ac8.png";
export const weba = "https://user-images.githubusercontent.com/43678736/132086707-e61a84de-d396-4dbf-8d1b-1d6ee19e1ac8.png";
export const webp = "https://user-images.githubusercontent.com/43678736/132086708-21d096dd-7148-40aa-97f1-cbb099339740.png";
export const wma = "https://user-images.githubusercontent.com/43678736/132086709-811d4e90-3cfa-4044-a956-aeda9c67fc92.png";
export const wmv = "https://user-images.githubusercontent.com/43678736/132086710-c5479c6c-0249-4542-adad-48b0ef40b775.png";
export const woff = "https://user-images.githubusercontent.com/43678736/132086711-1524a3e7-3e33-4822-a34f-ff3235404045.png";
export const xlsx = "https://user-images.githubusercontent.com/43678736/132086712-17e2c491-f6e4-4586-aef6-06bcc5f4b0e5.png";
export const xlsx2 = "https://user-images.githubusercontent.com/43678736/132086714-7ddf285d-2b83-4115-80a5-f02f510300a1.png";
export const xml = "https://user-images.githubusercontent.com/43678736/132086715-204b5a8b-9c5a-4bac-8294-9237ebc16089.png";
export const xul = "https://user-images.githubusercontent.com/43678736/132086716-64511d20-58cb-45a8-85df-f4d9408b469d.png";
export const zip = "https://user-images.githubusercontent.com/43678736/132086718-a8499333-6282-4820-aa1f-4d133eb54648.png";

//export { aac, abw, accdb, avi, azw, bmp, bz, bz2, cda, csh, css, csv, docx, drawio, eot, epub, freearc, gif, gzip, html, icalendar, jar, java, javascript, jpeg, json, jsonld, midi, mp3, mp4, mpeg, mpkg, octet, odp, ods, odt, opus, otf, pdf, php, png, pptx, psd, python, rar, react, rtf,sass, sevenzip, sh, swf, tar, text, tiff, ttf, typescript, vsd, vue, wav, weba, webm, webp, wma, wmv, woff, xlsx, xml, xul, zip };
